import React from "react";
import Styles from "./sensor.module.scss";
import WeatherSensor from "../../../assets/images/weather-sensor.png";

const SensorCard = ({ image, heading, detail }) => {
  return (
    <div className={Styles.sensorCardContainer}>
      <div
        className={Styles.imageContainer}
        style={{ background: "url(" + image + ")" }}
      ></div>
      <div className={Styles.details}>
        <h5>{heading}</h5>
        <p>{detail}</p>
      </div>
    </div>
  );
};

export default SensorCard;
