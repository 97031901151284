import React, { useEffect } from 'react';

// components
import Button from '../components/AButton';
import ContactForm1 from '../components/ContactForm1';
import HubSpotForm from '../components/HubSpotForm';

import AOS from 'aos';
import 'aos/dist/aos.css';

// images
import hero_image_plant from '../../assets/images/hero-image-plant.png';
import wheat_left_hero from '../../assets/images/wheat-left-hero.png';
import wheat_right_hero from '../../assets/images/wheat-right-hero.png';
import vector_hero_bottom from '../../assets/images/vector-hero-bottom.svg';
import WeatherSensor from "../../assets/images/weather-sensor.png";
import IrrigationSensor from "../../assets/images/feromon-sensor.png";
import FeremonSensor from "../../assets/images/irrigation-sensor.png";


// data
import features from '../data/features';
import faq from '../data/faq';
import Feature from '../components/Feature';
import TechnologyCard from '../components/Technology';
import technologies from '../data/technology';
import SensorCard from '../components/Sensors';
import customersOffers from '../data/customersOffers';

const Index = () => {

    useEffect(() => {
        AOS.init({
            once: true,
        });
        AOS.refresh();
        // Cleanup on component unmount
        return () => {
            AOS.refreshHard(); // Optional: Hard refresh on unmount to clear all AOS state
        };
    }, []);

    return (
        <>
            {/* hero - start */}
            <div className="hero hero-1">
                <div className="hero-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center offset-lg-0 order-lg-1 col-10 offset-1 order-2">
                                <div className="hero-content d-flex align-items-center flex-column">
                                    <h1 className="c-dark text-center" data-aos="fade-up"><span className='highlight'>Cultivating A</span> <img src={hero_image_plant} /> Sustainable Future Through Modern <span className='highlight'>Agriculture</span></h1>
                                    <p className="large c-grey" data-aos="fade-up">Advancing Pakistan's Agriculture for a Sustainable Future</p>
                                    <a href="#contact_us" className="a-underline link-underline-1">
                                    <Button
                                        className="w-full"
                                        size="Large"
                                        type="PrimaryRounded"
                                        data-aos="fade-up"
                                        data-aos-delay="50"
                                    >
                                        <span>GET A DEMO</span>
                                        
                                    </Button>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <img src={wheat_left_hero} className='wheat-left d-none d-md-block' data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-delay="150" />
                    <img src={wheat_right_hero} className='wheat-right'
                        data-aos="fade-up"
                        data-aos-easing="linear"
                        data-aos-delay="150" />
                    <img src={vector_hero_bottom} className='vector-bottom d-none d-md-block' />
                </div>
            </div>
            {/* hero - end */}

            {/* app feature - start */}
            <div className="app-feature app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                                <h3>Welcome to <span className='highlight'>AiGROS</span></h3>
                                <p>condition for agricultural production are changing everyday, increasingly challenging circumstances for
                                    producers in the face of unscalable and un familiar conditions.
                                    traditional methods are no longer effective of constantly agricultural conditions.</p>
                            </div>

                            <div className="col-lg-7">
                                <div className='row justify-content-center'>
                                    {features.map((element, key) => {
                                        return (
                                            <div className="col-lg-4 gy-5 gx-5" key={key}>
                                                <Feature
                                                    icon={element.icon}
                                                    content={element.content}
                                                    containerClass='app-feature-single-2'
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}
            <div className="video-section">
                <div className="video-section-wrapper">
                    <div className="container">
                        <div className="heading d-flex align-items-center flex-column">
                            <h3 className='text-center'>Your Farm's Prosperity Begins with Precise Measurement<br className='d-none d-xl-block'/> and <span className='highlight'>Invaluable Data!</span></h3>
                            <p className='text-center mt-5 paragraph-custom'>We firmly believe that the future of agriculture, geared towards a sustainable future, hinges on the integration of Sensors (IoT), Big Data, Artificial Intelligence, and advanced algorithms. It's evident that these technologies empower farmers to optimize their operations with reliable, science-driven data. AiGROS emerged from a vision to develop intelligent digital agricultural services, driven by the goal of enhancing farmers' livelihoods while contributing to a sustainable future.</p>
                        </div>

                        <div className='row justify-content-center'>
                            {technologies.map((element, key) => {
                                return (
                                    <div className="col-lg-3 gy-5 gx-5" key={key}>
                                        <TechnologyCard icon={element.icon} title={element.heading} description={element.excerpt} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>



            <div className="feature-section feature-section-0 feature-section-spacing-1" id='technologies'>
                <div className="feature-section-wrapper">
                    <div className="container">
                        <div className="heading  d-flex align-items-center flex-column mb-5">
                            <h3 className='text-center mb-4'>Our <span className='highlight'>Technologies</span></h3>
                            <p className='text-center paragraph-custom'>AiGROS desire is not to sell pure hardware but to bundle our IoT products withdigital smart services and focus on Sensor as a Service concept.This is why we introduced a unique sales strategy which is Agricultural Technology as a Service an annual subscription based model to make farming technology accessible and affordable</p>
                        </div>
                        <div className="row gx-5 mt-5">
                            <div className="col-lg-4 gy-5 gx-5" >
                                <SensorCard image={WeatherSensor} heading={'WEATHERAGRO STATION'} detail={'Increase crop quality & efficiency Input cost reduction Disease prevention through data analysis Critical calculations: Chilling Hours, Degree Day, Growing Degree Day, Dewing Point, Weather Forecast, ET0'} />
                            </div>
                            <div className="col-lg-4 gy-5 gx-5" >
                                <SensorCard image={FeremonSensor} heading={'IRRIGATEIRRIGATION OPTIMIZATION'} detail={'Right amount of irrigation at the right time Science based irrigation decisions Critical calculations with Artificial Intelligence Stop wasting precious resources Optimize inputs'} />
                            </div>
                            <div className="col-lg-4 gy-5 gx-5" >
                                <SensorCard image={IrrigationSensor} heading={'Pest Monitoring'} detail={'Reduction of operational costsOptimize pesticide applications’ timingsLess field visitsDecrease the residue levels in cr'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* video - end */}

            {/* feature section - start */}

            {/* feature section - end */}

            {/* app feature - start */}
            <div className="app-feature customer-get app-feature-1">
                <div className="app-feature-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 offset-lg-0 col-10 offset-1">
                                <h3>Customers Will Get </h3>
                                <p>AiGROS Agriculture revolutionizes farming with cutting-edge technology, offering farmers real-time weather forecasts tailored to their location.
                                    Save time and money by optimizing resource usage through satellite-based monitoring, ensuring efficient and sustainable agricultural practices.
                                    Our innovative platform provides agronomist support, empowering farmers with expert advice to enhance crop yields and minimize risks.
                                    Stay informed instantly with personalized alerts delivered through WhatsApp, keeping farmers updated on critical developments for proactive decision-making.
                                    AiGROS Agriculture is your partner in precision farming, leveraging advanced tools to boost productivity and maximize returns on every acre.</p>
                            </div>

                            <div className="col-lg-7">
                                <div className='row justify-content-center'>
                                    {customersOffers.map((element, key) => {
                                        return (
                                            <div className="col-lg-4 gy-5 gx-5" key={key}>
                                                <Feature
                                                    icon={element.icon}
                                                    content={element.content}
                                                    containerClass='app-feature-single-2'
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* app feature - end */}



            {/* faq section - start */}
            <div className="faq-section" id='why-us'>
                <div className="faq-section-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 offset-lg-0 col-12 ">
                                <p className='text-center fw-bold' style={{ color: 'rgba(46, 46, 46, 0.70)' }}>Why Us ?</p>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-9 col-12 ">
                                <div className="faq-wrapper">
                                    <div className="faq" id="faq-accordion">
                                        {
                                            faq.map((element, key) => {
                                                return (
                                                    <div className="accordion-item" key={key}>
                                                        <div className="accordion-header" id={`faq-${key}`}>
                                                            <button
                                                                className={`accordion-button collapsed`}
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#faq-collapse-${key}`}
                                                                aria-controls={`faq-collapse-${key}`}>
                                                                <img src={element.image} />
                                                                <span>{element.question}</span>
                                                            </button>
                                                        </div>
                                                        <div
                                                            id={`faq-collapse-${key}`}
                                                            className={`accordion-collapse collapse `}
                                                            aria-labelledby={`faq-${key}`}
                                                            data-bs-parent="#faq-accordion">
                                                            <div className="accordion-body">
                                                                <p>{element.answer}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* faq section - end */}

            {/* contact form section - start */}
            <div className="contact-form-section contact-form-section-1" id='contact_us'>
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                {/* <ContactForm1 /> */}
                                <HubSpotForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact form section - end */}
        </>
    );
};

export default Index;
