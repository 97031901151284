import feature1 from '../../assets/images/feature-1.svg';
import feature2 from '../../assets/images/feature-2.svg';
import feature3 from '../../assets/images/feature-3.svg';
import feature4 from '../../assets/images/feature-4.svg';
import feature5 from '../../assets/images/feature-5.svg';


const customersOffers = [
    {
        icon: feature1,
        heading: 'Easy to Use',
        content: 'Reduce expenses with smart farming.',
        to: '/feature-detail-1'
    },
    {
        icon: feature2,
        heading: 'Made for Everyone',
        content: 'Get timely, localized weather updates.',
        to: '/feature-detail-1'
    },
    {
        icon: feature3,
        heading: 'Share Everything',
        content: 'Enhance yield with satellite insights.',
        to: '/feature-detail-1'
    },
    {
        icon: feature4,
        heading: 'Unlimited Storage',
        content: 'Agricultural expert advice at hand.',
        to: '/feature-detail-1'
    },
    {
        icon: feature5,
        heading: 'Data Protection',
        content: 'Receive critical updates instantly on WhatsApp.',
        to: '/feature-detail-1'
    },
];

export default customersOffers;