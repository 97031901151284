// SDKs needed
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

// Firebase configuration
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
