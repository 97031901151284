import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import BackgroundAnimation from "../components/BackgroundAnimation";

import logo from "../../assets/images/aigros-logo.svg";
import logo_white from "../../assets/images/aigros-logo.svg";
import Button from "../components/AButton";

const navigation_responsive = () => {
  const width = window.innerWidth;
  const navigation = document.querySelector(".navigation");

  if (typeof navigation === "undefined" || navigation === null) return;

  if (width < 992) {
    navigation.classList.add("responsive");
  } else {
    navigation.classList.remove("responsive");
  }
};

const Header = ({ type }) => {
  const [showNavigation, setShowNavigation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", navigation_responsive);
    window.addEventListener("load", navigation_responsive);

    return () => {
      window.removeEventListener("resize", navigation_responsive);
      window.removeEventListener("load", navigation_responsive);
    };
  }, []);

  const toggleNavigation = () => {
    setShowNavigation((prev) => !prev);
  };

  const routeToLogin = () => {
    window.open("https://hub.aigros.com/customer/login", "_blank");
  };
  const hideMenu = (id) => {
    toggleNavigation();
    if (id) {
      let element = document.getElementById(id);
      if (element) {
        const offset = 90;
        const offsetTop = element.offsetTop - offset;

        element.scrollIntoView();
      }
    } else {
      let element = document.getElementById("root");
      if (element) {
        element.scrollIntoView();
      }
    }
  };

  return (
    <div
      className={`navigation ${type} scrolled ${showNavigation ? "shown" : ""}`}
    >
      <div className="navigation-wrapper">
        <div className="container">
          <div className="navigation-inner">
            <div className="navigation-logo">
              {type === "navigation-1" ? (
                <>
                  <Link className="logo" to="/">
                    <img src={logo} alt="aigros-logo" />
                  </Link>
                  <Link className="logo-white" to="/">
                    <img src={logo_white} alt="aigros-logo" />
                  </Link>
                </>
              ) : (
                <Link className="logo" to="/">
                  <img src={logo} alt="aigros-logo" width={170} />
                </Link>
              )}
            </div>
            <div className="navigation-menu">
              <div className="mobile-header">
                <div className="logo">
                  <Link to="/">
                    <img src={logo_white} alt="logo" />
                  </Link>
                </div>
                <ul>
                  <li className="close-button" onClick={toggleNavigation}>
                    <i className="fas fa-times"></i>
                  </li>
                </ul>
              </div>
              <ul className="parent">
                <li>
                  <a
                    onClick={() => {
                      return hideMenu();
                    }}
                    className="link-underline link-underline-1 mobile-menu-item"
                  >
                    <span>Home</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      return hideMenu("technologies");
                    }}
                    className="link-underline link-underline-1 mobile-menu-item"
                  >
                    <span>Technologies</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      return hideMenu("why-us");
                    }}
                    className="link-underline link-underline-1 mobile-menu-item"
                  >
                    <span>Why us</span>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      return hideMenu("contact_us");
                    }}
                    className="link-underline link-underline-1 mobile-menu-item"
                  >
                    <span>Contact us</span>
                  </a>
                </li>
              </ul>
              <div className="social" style={{ width: "100%" }}>
                <Button
                  className="w-full d-block d-md-none"
                  size="Large"
                  type="PrimaryWhite"
                  onClick={() => {
                    routeToLogin();
                  }}
                >
                  Login
                </Button>
              </div>
              <div className="background-pattern">
                {/* <BackgroundAnimation /> */}
                {/* <div className="background-pattern-gradient"></div> */}
              </div>
            </div>
            <Button
              className="w-full d-none d-md-block"
              size="Large"
              type="PrimaryRounded"
              onClick={() => {
                routeToLogin();
              }}
            >
              Login
            </Button>
            <div className="navigation-bar" onClick={toggleNavigation}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
