import feature1 from '../../assets/images/feature-1.svg';
import feature2 from '../../assets/images/feature-2.svg';
import feature3 from '../../assets/images/feature-3.svg';
import feature4 from '../../assets/images/feature-4.svg';
import feature5 from '../../assets/images/feature-5.svg';


const features = [
    {
        icon: feature1,
        heading: 'Easy to Use',
        content: 'Enhance crop quality and efficiency.',
        to: '/feature-detail-1'
    },
    {
        icon: feature2,
        heading: 'Made for Everyone',
        content: 'Manage costs for greater farm profitability.',
        to: '/feature-detail-1'
    },
    {
        icon: feature3,
        heading: 'Share Everything',
        content: 'Tailored care for each field and harvest.',
        to: '/feature-detail-1'
    },
    {
        icon: feature4,
        heading: 'Unlimited Storage',
        content: 'Ideal conditions monitored for peak growth.',
        to: '/feature-detail-1'
    },
    {
        icon: feature5,
        heading: 'Data Protection',
        content: 'AI-driven irrigation for science-based decisions.',
        to: '/feature-detail-1'
    },
];

export default features;