import technology1 from '../../assets/images/sensor-agriculture.svg';
import technology2 from '../../assets/images/farming-tech.svg';
import technology3 from '../../assets/images/algorithm-scheme-workflow.svg';
import technology4 from '../../assets/images/labour-cultivation.svg';


const technologies = [
    {
        icon: technology1,
        heading: 'Field & Farming Data',
        excerpt: 'We design & manufacture our own LoT products. We also integrate dat from various software applications and resources',
        to: '/feature-detail-1'
    },
    {
        icon: technology2,
        heading: 'AiGROS AI platform',
        excerpt: 'We use Statistic machine Learning & Artificial intelligence within our AI platform to process analyze and correlate',
        to: '/feature-detail-1'
    },
    {
        icon: technology3,
        heading: 'Algorithms',
        excerpt: 'We convert big data into algorithms and make it relevant',
        to: '/feature-detail-1'
    },
    {
        icon: technology4,
        heading: 'Digital Agriculture Services',
        excerpt: 'We transform these algorithms into simple user-friendly services',
        to: '/feature-detail-1'
    },
];

export default technologies;