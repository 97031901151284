const SOCIALMEDIALINKS = [
  {
    name: "LinkedIn",
    class: "fab fa-linkedin",
    link: "https://www.linkedin.com/company/aigros-tech/"
  },
  {
    name: "Instagram",
    class: "fab fa-instagram",
    link: "https://www.instagram.com/aigros_tech/"
  },
  {
    name: "Facebook",
    class: "fab fa-facebook",
    link: "https://www.facebook.com/aigros.official"
  },
  {
    name: "Youtube",
    class: "fab fa-youtube",
    link: "https://www.youtube.com/@AiGROSTech"
  },
  {
    name: "TikTok",
    class: "fab fa-tiktok",
    link: "https://www.tiktok.com/@aigros_tech?_t=8p3roYRAZYf&_r=1"
  }
];

export default SOCIALMEDIALINKS;
