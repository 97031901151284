const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

export const postContactForn = (requestPayload) => {
  return fetch(`${API_BASE_URL}/contact-farm`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": API_KEY
    },
    body: JSON.stringify(requestPayload)
  });
};
