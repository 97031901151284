import React from "react";

import { Link } from "react-router-dom";

// Social media links
import SOCIALMEDIALINKS from "../data/socialMedia";

// images
import logo from "../../assets/images/aigros-logo.svg";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                {/* <div class="form-group row">
                                <label for="inputPassword" class="col-sm-2 col-form-label">Password</label>
                                <div class="col-sm-10">
                                    <input type="password" class="form-control" id="inputPassword" placeholder="Password" />
                                </div>
                            </div> */}
                <div className="footer-row">
                  <div className="footer-detail">
                    <Link to="#">
                      <img
                        src={logo}
                        width={200}
                        alt="footer-logo"
                        style={{ background: "#fff", padding: "15px" }}
                      />
                    </Link>
                  </div>
                  <div className="footer-list footer-social social-gradient">
                    <h6>Menu</h6>
                    <ul>
                      <li >
                        <Link to="/" className="link-underline">
                          <span>Home </span>
                        </Link>
                      </li>
                      <li >
                        <a href="#technologies" className="link-underline">
                          <span>Technologies </span>
                        </a>
                      </li>
                      <li >
                        <a href="#why-us" className="link-underline">
                          <span>Why us </span>
                        </a>
                      </li>
                      <li >
                        <a href="#contact_us" className="link-underline">
                          <span>Contact us</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-list">
                    <h6>Will Get</h6>
                    <ul>
                      <li>
                        <span>Save money</span>
                      </li>
                      <li>
                          <span>Weather forecast</span>
                      </li>
                      <li>
                          <span>Satellite based monitoring</span>
                      </li>
                      <li>
                          <span>Agronomist support</span>
                      </li>
                      <li>
                          <span>Real time alerts on whats app</span>
                      </li>
                    </ul>
                  </div>
                  
                  <div className="footer-list social-list">
                    <h6>Follow Us</h6>
                    <ul>
                      <li>
                      {
                    SOCIALMEDIALINKS && SOCIALMEDIALINKS.map(socialMedia => (
                        <span key={socialMedia.name}>
                          <a href={socialMedia.link} target="_blank">
                            <i className={socialMedia.class} />
                          </a>
                        </span>
                    ))
                  }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="terms-condition container w-100">
        <div className="d-flex justify-content-between w-100">
          <p>Copyright © 2024 AiGROS</p>
          <div className="d-flex">
            <p>Privacy Policy</p>
            <p className="ms-3">Term of Service</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
