import React from 'react';

// react router dom
import { Routes, Route, useLocation } from 'react-router-dom';

// pages
import Header from './layout/Header';
import Index from './pages/Index';
import Footer from './layout/Footer';
import CustomScrollbar from './components/CustomScrollbar';
import Preloader from './components/Preloader';

// toast
import { Toaster } from "react-hot-toast";

const Markup = () => {

    return (
        <>
            <Preloader />
            <CustomScrollbar />
            <div className="main-wrapper">
                {/* header - start */}
                <Header type={useLocation().pathname !== '/' ? '/' : '/'} />
                {/* header - end */}
                <Routes>
                    <Route path="/" element={<Index />} />
                </Routes>
                {/* footer - start */}
                <Footer />
                {/* footer - end */}
                <Toaster position="top-right" />
            </div>
        </>
    );
};

export default Markup;
