import faq1 from '../../assets/images/SVG-1.svg';
import faq2 from '../../assets/images/SVG-2.svg';
import faq3 from '../../assets/images/SVG-3.svg';
import faq4 from '../../assets/images/SVG.svg';
import faq5 from '../../assets/images/users-three 1.svg';

const faq = [
    {
        question: 'We are one of the leading companies that uses the power of Artificial intelligence to derive meaningful insights from the raw data',
        answer: 'AIGROS stands at the forefront of utilizing Artificial Intelligence to transform raw data into valuable insights. Our expertise in AI enables us to identify patterns and trends that are critical for data-driven decision-making in various sectors.',
        image: faq4
    },
    {
        question: 'We provide real time communication with your systems through AiGROS Smart Assistant - integrated BOT software within Telegram.',
        answer: 'Our integrated BOT software within Telegram facilitates seamless, real-time communication with your systems. This innovative tool enhances operational efficiency and responsiveness, enabling immediate interactions and data exchanges.',
        image: faq1
    },
    {
        question: 'We develop our own agricultural technologies simple and user-friendly.',
        answer: 'We specialize in developing agricultural technologies that are not only advanced but also remarkably user-friendly. Our focus is on simplicity and ease of use, ensuring that our tech solutions are accessible to all users, regardless of their technical expertise.',
        image: faq5
    },
    {
        question: 'We offer access to our technologies at affordable prices with our unique subscription business model.',
        answer: 'AIGROS offers its cutting-edge technologies at accessible prices through a unique subscription business model. This approach makes our advanced solutions more affordable and available to a broader range of customers, democratizing access to top-tier tech.',
        image: faq2
    },
    {
        question: 'We provide direct product & service installation support on fields.',
        answer: 'We provide hands-on product and service installation support directly in the fields. Our team ensures that our technologies are implemented effectively and efficiently, offering personalized assistance to meet the unique needs of each client.',
        image: faq3
    }
];

export default faq;