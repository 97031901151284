import React from "react";
import Styles from "./technology.module.scss";

const TechnologyCard = ({ icon, title, description }) => {
  return (
    <div className={Styles.technologyCardContainer}>
      <div>
        <img src={icon} />
      </div>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};

export default TechnologyCard;
