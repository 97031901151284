import React, { useEffect, useRef } from "react";
import SectionHeading from "./SectionHeading";
// import css from "../../assets/scss/_hubspot_form.css";

const HubSpotForm = () => {
  const formContainerRef = useRef(null);

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Once the script is loaded, initialize the form
    script.onload = () => {
      if (window.hbspt && formContainerRef.current) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "43766742",
          cssClass: "hub-spot-form",
          css: ` `,
          formId: "df97d159-5e15-4dea-92ed-ec1a8af021c5",
          target: `#${formContainerRef.current.id}`, // Specify the target container
          onFormSubmit: handleFormSubmit // Attach the form submission handler
        });
      }
    };

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleFormSubmit = (event) => {
    console.log("event", event);
    // Prevent default behavior
    event.preventDefault();

    // Access form data if needed
    const formData = new FormData(event.target);

    // Perform your custom logic here, such as logging the form data
    for (let [name, value] of formData.entries()) {
      console.log(name, value);
    }

    // Optionally, you can make an API call or perform other actions here

    // Allow the form to submit as usual if needed
    // event.target.submit();

    // Custom logic for form submission can be added here
    // For example, you can log the event or send data to an API
  };

  return (
    <div className="contact-form">
      <div className="contact-form-wrapper">
        <SectionHeading
          heading={"Contact us"}
          subHeading="We’d love to hear from you. Please fill out this form."
          additionalClasses="section-heading-2 center"
        />
        <div id="hubspot-form-container" ref={formContainerRef}>
          {/* The form will be rendered inside this container */}
        </div>
      </div>
    </div>
  );
};

export default HubSpotForm;
