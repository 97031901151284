import React from "react";
import Styles from "./feature.module.scss";

const Feature = ({ icon, content }) => {
  return (
    <div className={Styles.featureContainer}>
      <div className={Styles.imageContainer}>
        <img src={icon} />
      </div>
      <p>{content}</p>
    </div>
  );
};

export default Feature;
