import React from "react";
import Styles from "./button.module.scss";
import { NavLink } from "react-router-dom";

const Button = ({
  as = "button",
  htmlType = "button",
  type,
  backgroundColor,
  size,
  className,
  children,
  loading,
  disabled = false,
  textColor,
  icon = null,
  ...rest
}) => {
  return as === "button" ? (
    <button
      type={htmlType}
      className={[Styles.button, Styles[size], Styles[type], className].join(
        " "
      )}
      style={backgroundColor && { backgroundColor }}
      disabled={loading || disabled}
      {...rest}
    >
      {!loading && <span className="text-white">{children}</span>}
      {loading && <><div className="chaotic-orbit"></div> </> }
    </button>
  ) : as === "navLink" ? (
    <NavLink
      className={[
        Styles.button,
        Styles.link,
        Styles[size],
        Styles[type],
        className,
      ].join(" ")}
      style={backgroundColor && { backgroundColor }}
      {...rest}
    >
      {!loading && (
        <span className="text-white flex items-center justify-between">
          {icon && <span className={`mr-1.5 mt-1 ${textColor}`}>{icon}</span>}{" "}
          <span className={textColor}>{children}</span>
        </span>
      )}
      {loading && <div className="chaotic-orbit"></div>}
    </NavLink>
  ) : (
    <a
      className={[
        Styles.button,
        Styles.link,
        Styles[size],
        Styles[type],
        className,
      ].join(" ")}
      style={backgroundColor && { backgroundColor }}
      {...rest}
    >
      {!loading && (
        <span className="text-white flex items-center justify-between">
          {icon && (
            <span
              className={`mr-1.5 mt-1 ${textColor}  ${
                textColor === "danger" ? Styles.success : Styles.danger
              }`}
            >
              {icon}
            </span>
          )}{" "}
          <span className={textColor}>{children}</span>
        </span>
      )}
      {loading && <div className="chaotic-orbit"></div>}
    </a>
  );
};

export default Button;
